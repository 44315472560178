.react-datepicker__input-container > input {
    border-radius: 2px;
    padding-left: 15px !important;
    font-family: Montserrat !important;
    font-size: 12px !important;
    height: 36px !important;
    width: 200px !important;
}

.react-datepicker-ignore-onclickoutside {
    background-color: #3e82cf;
}

.light-picker {
    background-color: #f5f5f5;
    color: #1d0928;
}

.dark-picker {
    background-color: #1e172f;
    color: #b3a7cb;
}
