.blurCard {
    opacity: 0.3;
    animation: fade 0.3s;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.3;
    }
}
.blurLeave {
    opacity: 1;
    animation: outFade 0.3s;
}
@keyframes outFade {
    0% {
        opacity: 0.3;
    }

    100% {
        opacity: 1;
    }
}
